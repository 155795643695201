<template>
  <el-dialog title="请选择添加条款类型"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    :close-on-click-modal="false"
    width="640px"
    :destroy-on-close="true"
    @close="dialogClose"
    :before-close="handleClose">
    <div class="content">
      <div class="select-wrap">
        <div :class="{'select-item':true,'active':curSelect === index}"
          v-for="(item,index) in selectArr"
          :key="item.name"
          @click="curSelect = index">
          <img class="icon"
            :src="'https://yiligps.oss-cn-shenzhen.aliyuncs.com/ui-show/allocationType' + (index + 1) + '.png'"
            alt="">
          <div class="info">
            <div class="name">{{item.name}}</div>
            <div class="remark">{{item.remark}}</div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="reset">取 消</el-button>
      <el-button type="primary"
        @click="submit">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>

export default {
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  props: {
    showDialog: Boolean,
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        }
      }
    },
    info: {
      handler(v) {
        if (v) {
        }
      }
    },
  },
  data() {
    return {
      dialogVisible: false,
      selectArr: [{ name: '主险', remark: '添加一个主险', img: '' }, { name: '附加险', remark: '添加一个方案中的附加险', img: '' }, { name: '特别约定', remark: '添加一个方案中的特别约定', img: '' }, { name: '免费扩展条款', remark: '添加一个方案中的免费扩展条款', img: '' }, { name: '特别说明', remark: '添加一个方案中的特别说明', img: '' }, { name: '每次事故绝对免赔额', remark: '添加一个方案中的每次事故绝对免赔额', img: '' }],
      curSelect: null
    };
  },
  created() {

  },
  computed: {

  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    reset() {
      this.dialogVisible = false;
      this.curSelect = null;
    },
    submit() {
      if (this.curSelect !== null) {
        this.$emit('selectType', this.curSelect);
        this.reset();
      } else {
        this.$message.error('请选择类型');
      }
    },
    dialogClose() {
    },
  },
}
</script>
<style lang="scss" scoped>
.select-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .select-item {
    width: 49%;
    border-radius: 10px;
    border: 2px solid transparent;
    padding: 20px 15px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    box-shadow: 1px 1px 5px rgba($color: #4278c9, $alpha: 0.6);
    &:nth-child(5),
    &:nth-child(6) {
      margin-bottom: 0;
    }
    .icon {
      width: 65px;
      height: 65px;
      margin-right: 15px;
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 60px;
      .name {
        font-size: 18px;
        font-weight: bold;
        color: #666;
      }
      .remark {
        font-size: 12px;
        color: #9c9c9c;
      }
    }
  }
  .active {
    border-color: #4278c9;
  }
}
::v-deep .policy-dialog {
  border-radius: 8px;
  .el-dialog__header {
    font-weight: bold;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background: linear-gradient(to bottom, #ebf3ff, #fff);
    .el-dialog__title {
      color: #4278c9;
      font-size: 32px;
    }
  }
}
</style>
