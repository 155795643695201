<template>
  <div>
    <div class="type-select"
      v-if="categoryCount.length > 0">
      <div class="item"
        :class="{ active: curType === 1 }"
        @click="handleSelect(1)">
        工程机械({{categoryCount[0].count || '0'}})
      </div>
      <div class="item"
        :class="{ active: curType === 2 }"
        @click="handleSelect(2)">
        车险({{categoryCount[1].count || '0'}})
      </div>
      <div class="item"
        :class="{ active: curType === 3 }"
        @click="handleSelect(3)">
        非设备类财产险({{categoryCount[2].count || '0'}})
      </div>
    </div>
    <GeneralTable ref="GeneralTable"
      tableName="ClauseDocumentTable"
      @add-change="handleEdit({},'添加')"
      @search-change="handleSearch"
      @del-change="handleDel"
      @export-data="handleExport"
      :dataList="dataList"
      :column="column"
      :option="option"
      :totalNum="totalNum">
      <template slot="operate"
        slot-scope="{ item }">
        <el-button type="text"
          @click="getDetail(item.id,'check')">查看内容</el-button>
        <el-button type="text"
          @click="openTips(item)">编辑</el-button>
        <el-button type="text"
          @click="handleDel([item])">删除</el-button>
      </template>
    </GeneralTable>
    <edit-dialog v-model="showDialog"
      @selectType="selectType" />
    <form-dialog @change="handleVisible"
      v-model="showFormDialog"
      :formType="formType"
      :category="curType"
      :info="curInfo" />
    <el-dialog title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <span>编辑此条款将会影响到<span style="color:#C94242;margin:0 10px">{{curCol.insureProgrammeName || '--'}}</span>是否继续</span>
      <span slot="footer"
        class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary"
          @click="getDetail(curCol.id,'update')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { dictionaryBatch, getAllocationPage, getAllocationPageTotal, delConfiguration, getConfigurationDetail, exportAllocation } from "@/api/policy";
import GeneralTable from "@/components/GeneralTable/index.vue";
import editDialog from './components/editDialog.vue';
import formDialog from './components/formDialog.vue';
export default {
  components: {
    GeneralTable,
    editDialog,
    formDialog
  },
  data() {
    return {
      curCol: {},
      dialogVisible: false,
      formType: 6,
      curType: 1,
      dict: {},
      showDialog: false,
      showFormDialog: false,
      dataList: [],
      totalNum: 0,
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "名称",
          prop: "termConfigurationName",
          isShow: true,
        },
        {
          label: "分类",
          prop: "classificationName",
          isShow: true,
        },
        {
          label: "保险公司",
          prop: "companyName",
          isShow: true,
        },
        {
          label: "所属承保方案",
          prop: "insureProgrammeName",
          isShow: true,
        },
        {
          label: "录入员",
          prop: "enteredName",
          isShow: true,
        },
        {
          label: "创建时间",
          prop: "createTime",
          isShow: true,
        },
        {
          label: "更新时间",
          prop: "updateTime",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          fixed: "right",
          width: 200,
          isShow: true,
          isSlot: true,
        },
      ],
      option: {
        isAdd: true, //添加 事件 @add-change
        isDel: true, //删除 @del-change
        isEdit: true, // 编辑 @edit-change
        isExport: true,
        isSearch: true, // 搜索 @search-change
        isRefresh: true, // 刷新 @search-change
        pageSize: 10,
        searchList: [
          {
            label: "名称",
            prop: "termConfigurationName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "分类",
            prop: "classificationName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "保险公司",
            prop: "companyName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "所属承保方案",
            prop: "insureProgrammeName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            labelWidth: '100px'
          },
          {
            label: "录单员",
            prop: "enteredName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "创建时间",
            formType: "datetimerange",
            clearable: true,
            prop: ["createTimeBegin", "createTimeEnd"],
          },
          {
            label: "更新时间",
            formType: "datetimerange",
            clearable: true,
            prop: ["updateTimeBegin", "updateTimeEnd"],
          },
        ],
      },
      categoryCount: [],
      curInfo: {}
    };
  },
  mounted() {
  },
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    getTotal(data) {
      getAllocationPageTotal(data).then(res => {
        this.categoryCount = res.data;
      });
    },
    getDetail(id, type) {
      this.dialogVisible = false;
      getConfigurationDetail({ id }).then(res => {
        res.data.insPolicyInsuranceTypeAppendEntityList = res.data.insPolicyInsuranceTypeAppendRespList;
        res.data.insPolicyInsuranceTypeAppendEntityList.forEach(el => {
          delete el.id;
        });
        if (type === 'check') res.data.check = true;
        this.curInfo = res.data;
        this.formType = res.data.dataType;
        this.showFormDialog = true;
      });
    },
    handleSearch(data) {
      data.current = data.pageNum;
      data.size = data.pageSize;
      data.fastBlur = data.keyword;
      data.category = this.curType;
      this.getTotal(data);
      this.getList(data);
    },
    getList(data) {
      getAllocationPage(data).then(res => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleDel(data) {
      if (data && data.length) {
        this.$confirm('确认删除配置?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let temp = {
            ids: []
          };
          data.forEach(element => {
            temp.ids.push(element.id);
          });
          //  temp.ids = temp.ids.join(',');
          delConfiguration(temp.ids).then(res => {
            if (res) {
              this.$message({
                message: '操作成功',
                type: 'success'
              });
              this.changeList();
            }
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      } else {
        this.$message.error('请选择需要删除的配置！');
      }
    },
    openTips(item) {
      this.curCol = item;
      if (item.insureProgrammeName) {
        this.dialogVisible = true;
      } else {
        this.getDetail(this.curCol.id, 'update');
      }
    },
    handleExport(data, obj) {
      let ids = [];
      data.forEach(element => {
        ids.push(element.id);
      });
      obj.ids = ids;
      exportAllocation(ids).then(res => {
        if (res) {
          this.$message({
            message: '导出成功',
            type: 'success'
          });
        }
      });
    },
    handleSelect(i) {
      this.curType = i;
      this.$refs.GeneralTable.getList();
    },
    handleEdit(item) {
      this.curInfo = {};
      this.showDialog = true;
    },
    selectType(type) {
      this.showFormDialog = true;
      this.formType = type;
    },
    handleVisible(item) {
      this.formType = 6;
      this.changeList();
      this.getTotal();
    },
    changeList() {
      this.$refs.GeneralTable.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
.type-select {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin-bottom: -10px;
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  .item {
    position: relative;
    padding: 13px 20px;
    color: #606266;
    margin-right: 20px;
    cursor: pointer;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      color: #409eff;
    }
  }
  .active {
    color: #4278c9;
    font-weight: bold;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      border-radius: 4px;
      background-color: #4278c9;
    }
  }
}
</style>